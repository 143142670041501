import { MainLayout } from 'components/Layout';
import React, { useEffect } from 'react';

export const Privacy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <MainLayout title="Privacy Policy" description="Privacy Policy of Justknow">
      <div className="px-4 py-6 max-w-screen-md mx-auto">
        <h1 className="text-3xl font-semibold mb-4">Privacy Policy</h1>
        <p className="text-sm text-gray-600 mb-6">Last updated: 12th January, 2025</p>

        <p className="mb-4">
          Welcome to Justknow! Your privacy and data security are essential to us. This Privacy
          Policy describes what data we collect, how it is used, and your rights regarding your
          information. Our policies align with relevant privacy laws and Google Play requirements to
          protect your information and ensure a safe experience.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">1. Information We Collect</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Personal Information:</strong> We may collect personal details, such as your
            name, email address, phone number, and, if necessary, identification for account
            verification and support.
          </li>
          <li>
            <strong>Location Data:</strong> Location information is gathered to provide local
            services and rides. Your location is accessed only when the app is in use and is
            essential for core app functionality.
          </li>
          <li>
            <strong>Usage Data:</strong> We collect app usage information, such as features accessed
            and interactions, to improve app performance and user experience.
          </li>
          <li>
            <strong>Device Information:</strong> To enhance app functionality, we collect
            information about your device, such as model, operating system, and unique device
            identifiers, ensuring compatibility with our services.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">2. How We Use Your Information</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Connecting with Local Services:</strong> Location and personal data are used to
            connect you with local businesses, drivers, and services relevant to your needs.
          </li>
          <li>
            <strong>Ride Hailing and Delivery Services:</strong> To facilitate ride-hailing and
            delivery, we allow riders and drivers to set and accept custom fares.
          </li>
          <li>
            <strong>Analytics and Improvement:</strong> Usage data helps us monitor performance,
            improve features, and troubleshoot issues.
          </li>
          <li>
            <strong>Legal Compliance and Security:</strong> We may use or disclose data for
            compliance with legal obligations, to detect abuse or fraud, and to protect user rights.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">
          3. Permissions and APIs That Access Sensitive Information
        </h2>
        <p className="mb-4">
          Our app requests necessary permissions to ensure functionality and safety:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Location Access:</strong> Used for connecting users with local services and
            accurate route mapping for ride-hailing.
          </li>
          <li>
            <strong>Device Information:</strong> Accessed to optimize user experience based on
            device compatibility.
          </li>
          <li>
            <strong>Network Access:</strong> Used to support app functionality, such as connecting
            with drivers and business partners.
          </li>
        </ul>
        <p className="mb-4">
          Permissions are only used as needed, following Android and Google Play policies. You can
          manage these permissions within your device’s settings.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">4. Data Sharing and Disclosure</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>With Business Partners:</strong> Data is shared with partnered businesses and
            drivers only as needed to provide requested services.
          </li>
          <li>
            <strong>Legal Requirements:</strong> Information may be disclosed to comply with legal
            obligations or to protect safety and security as required by law.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">5. Data Security</h2>
        <p className="mb-4">
          We implement industry-standard security practices to safeguard your data against
          unauthorized access, alteration, disclosure, or destruction. However, no method of
          transmission or storage is entirely secure. Please contact us if you suspect any security
          breach.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">6. Your Rights</h2>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Access and Control:</strong> You may access, update, or delete your personal
            data in your account settings or by contacting support.
          </li>
          <li>
            <strong>Location Tracking:</strong> You can enable or disable location tracking at any
            time in your device settings, although disabling location access may affect core app
            functionalities.
          </li>
          <li>
            <strong>Data Portability and Deletion:</strong> Upon request, we will provide a copy of
            your data in a structured format or delete it as required by applicable laws.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">7. Device and Network Abuse Prevention</h2>
        <p className="mb-4">
          Our app prevents unauthorized device access or network misuse by strictly following Google
          Play’s Device and Network Abuse policies. We monitor for and block any suspicious or
          malicious activity that might compromise user safety or data integrity.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">
          8. Deceptive Behavior and Misrepresentation
        </h2>
        <p className="mb-4">
          Justknow commits to transparency in functionality and intent. We provide accurate
          descriptions of our services and use permissions solely for their described purposes,
          adhering to Google Play’s Deceptive Behavior policies.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">9. Cookies and Tracking</h2>
        <p className="mb-4">
          Our app may use cookies or similar technologies to enhance your experience by remembering
          preferences and conducting analytics. You can manage these settings through your device or
          app permissions.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">
          10. Compliance with Google Play’s Target API Level Policy
        </h2>
        <p className="mb-4">
          Our app maintains compatibility with the latest Android versions by meeting Google Play’s
          Target API Level Policy, ensuring a secure and optimized experience for all users.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">11. Age Requirement</h2>
        <p className="mb-4">
          Justknow is intended for users aged 18 and above. By signing up, you confirm that you meet
          this age requirement.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">12. Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update this policy periodically to reflect changes in our practices or legal
          requirements. Notice of significant updates will be provided via in-app notifications or
          email.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
        <p className="mb-4">
          If you have questions or concerns about this policy, please contact us at{' '}
          <strong>jonahgeek@gmail.com</strong>.
        </p>
      </div>
    </MainLayout>
  );
};
