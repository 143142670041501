import { MainLayout } from 'components/Layout';
import React, { useEffect } from 'react';

export const CSAE = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <MainLayout title="CSAE Policy" description="Our Safety and Abuse Prevention Policy">
      <div className="mx-auto">
        <h1 className="text-3xl font-bold text-orange-500 mb-4">
          Child Safety and Abuse Prevention Policy
        </h1>
        <p className="text-sm text-gray-600 mb-8">
          <strong>Effective Date:</strong> 13th January, 2024
        </p>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Purpose and Scope</h2>
          <p className="text-gray-700">
            Justknow is committed to creating a safe and respectful environment across all our
            platforms, including social media, business registration, ride-hailing, and delivery
            services. This policy outlines our commitment to preventing child sexual abuse,
            exploitation, and harmful behavior, ensuring compliance with international and local
            laws. It applies to all users, partners, and content contributors.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Prohibited Content and Activities
          </h2>
          <p className="text-gray-700">
            We strictly prohibit the creation, sharing, or promotion of the following content and
            activities:
          </p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Child Sexual Abuse Material (CSAM)</li>
            <li>Grooming and Predatory Behavior</li>
            <li>Human Trafficking and Exploitation</li>
            <li>Violent, Harmful, and Obscene Content</li>
            <li>Unauthorized collection or distribution of children's personal information</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">User Responsibilities</h2>
          <p className="text-gray-700">
            All users of Justknow’s services must adhere to this policy by:
          </p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Refraining from creating, sharing, or promoting any prohibited content</li>
            <li>
              Reporting suspicious activities or content using our in-app tools or by contacting
              support
            </li>
            <li>
              Ensuring compliance with all applicable laws and guidelines regarding child safety
            </li>
          </ul>
          <p className="text-gray-700 mt-2">To report violations, please contact us:</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Email:{' '}
              <a href="mailto:jonahgeek@gmail.com" className="text-orange-600 underline">
                jonahgeek@gmail.com
              </a>
            </li>
            <li>Phone: +256 782692271</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Moderation and Enforcement</h2>
          <p className="text-gray-700">
            Our moderation efforts include a combination of automated systems and human reviewers to
            detect and act on policy violations. Consequences for violations include:
          </p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Immediate removal of offending content</li>
            <li>Account suspension or termination</li>
            <li>Reporting illegal activities to relevant law enforcement authorities</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Education and Awareness</h2>
          <p className="text-gray-700">
            We are dedicated to promoting awareness about online safety. Our resources include:
          </p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Guidance on recognizing grooming and predatory behaviors</li>
            <li>Best practices for protecting personal information</li>
            <li>Safety tips for parents, children, and businesses using our services</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Reporting and Contact Information
          </h2>
          <p className="text-gray-700">
            If you encounter any issues or have concerns, please reach out to us:
          </p>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Email:{' '}
              <a href="mailto:jonahgeek@gmail.com" className="text-orange-600 underline">
                jonahgeek@gmail.com
              </a>
            </li>
            <li>Phone: +256 782692271</li>
            <li>Address: Kampala, Uganda</li>
          </ul>
        </section>
      </div>
    </MainLayout>
  );
};
