import { useRoutes } from 'react-router-dom';
import { CSAE, Landing, Privacy } from 'features/misc';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/csae', element: <CSAE /> },
  ];
  const routes = publicRoutes;
  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
